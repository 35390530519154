.multiValueLabel {
	display: flex;
	height: 20px;
	align-items: center;
}

.folderIconCont {
	color: #555;
}

.multiValueLabelImgCont {
	display: flex;
}

.multiValueLabelImg {
	width: 20px;
	border-radius: 2px;
}

.multiValueLabelTxt {

}

.clearIndicator {
	font-size: 18px;
}
