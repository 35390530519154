.optLabel {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 5px;
	font-size: 14px;
}

.optFeed {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 25px;
	font-size: 13px;
}
