.root {
	display: flex;
	flex-direction: column;
    padding-right: 1em;
}

.header {
	display: flex;
	align-items: center;
    cursor: default;
}

.headerClickable {
	cursor: pointer;
}

.expandCont {
    display: flex;
    width: 1.75em;
}

.expand {
    color: #555;
}

.logo {
	width: 1.25em;
	border-radius: .125em;
}

.logoCont {
	display: flex;
}

.title {
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
	margin-left: .5em;
	color: #444;
}

.unsub {
    display: flex;
}

.feeds {
	display: flex;
	flex-direction: column;
    margin-top: .5em;
	margin-left: 3.5em;
	gap: .5em;
}

.close {
    font-weight: 200;
    color: #ccc;
    cursor: pointer;

    &:hover {
        color: #555;
    }
}
