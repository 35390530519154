.root {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 3em;
	height: 3em;
}

.iconCont {
	font-size: 1.2em;
	cursor: pointer;
	color: #333;
}
