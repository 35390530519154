@import '@ui/styles/variables.scss';

.body {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.logoCont {
	display: flex;

	img {
		border-radius: 3px;
	}
}

.title {
    margin-left: .75em;
    font-size: 1em;
}

.btnSubscribe {
	composes: sm from '@ui/styles/buttons.scss';
	composes: rd2 from '@ui/styles/common.scss';
}
