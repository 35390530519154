@import '@ui/styles/variables.scss';

.root {
	display: flex;
	flex: 1;
	overflow: auto;
	justify-content: center;
}

.searchResultList {
	display: flex;
	flex-direction: column;
	width: $lgCenterWidth;
	background-color: white;
	overflow: auto;

	&.mobile {
		width: initial;
		padding: 1em;
		border: none;
		border-radius: 0;
		box-shadow: none;
	}
}

.emptySearchResults {
	position: absolute;
	left: 0;
	right: 0;
	width: fit-content;
	margin: 1em auto 0 auto;
	text-align: center;
	font-weight: bold;
	color: #aaa;
	cursor: default;
}

.cont {
}
