.root {
	display: flex;
    padding: 2.5em;
    border-bottom: 1px solid #ccc;

	h4 {
		margin-bottom: .5rem;
	}

	p {
		margin-bottom: .5rem;
	}
}

.title {
	font-size: 2.25rem;
	cursor: default;
}

.logo {
	width: 9em;
	border-radius: .25em;
}

.mobileHeader {
	display: flex;
	flex-direction: row;
}

.mobileHeaderMain {
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-left: .5em;
	justify-content: center;
	overflow: hidden;
}

.mobileLogo {
	width: 6em;
	height: 6em;
	border-radius: .125em;
}

.main {
	display: flex;
	margin-left: 2em;
    flex: 1;
}

.mainArticle {
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;

	aside {
		display: flex;
	}

	.header {
		display: flex;
		flex-direction: column;
		margin-bottom: 1em;
		color: #333;
	}

	.headerTop {
		display: flex;
        flex: 1;
		margin-bottom: .25em;
        justify-content: space-between;

		&.mobile {
			flex-direction: column;
		}
	}

	.headerBottom {
	}

	.mobilePubLink {
		margin-top: .25em;
		font-size: .6em;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	p {
		font-size: .9em;
		line-height: 1.5em;
		color: #111;
	}

    a {
        text-decoration: none;
		color: #111;

        &:hover {
            text-decoration: underline;
        }
    }
}

.mainArticle.mobile {
	p {
		margin-top: .5em;
	}
}

.icon {
	margin-left: .5em;
	font-size: .75em;
}

.subBtn {
    width: 190px;
	padding: .75em 2.25em;
	border-radius: 5px;
	font-size: 1.1em;
	color: white;
	background-color: black;
}

.mobileSubBtn {
	margin-left: 0;
	margin-top: 1em;
	padding: .5em 1em;
	font-size: .9em;
}

.feedRow {
	display: flex;
}

.tags {
	display: flex;
	margin-top: .25em;
	gap: .75em;
	font-size: .9em;
	font-family: monospace;
	font-style: italic;
	color: #999;
	cursor: default;
}

.tag {
	display: flex;
}
