.root {
	display: flex;
    align-items: center;
}

.title {
    display: flex;
	font-size: .9em;
	color: #333;
	cursor: default;
}

.unsub {
    display: flex;
	margin-left: .5em;
	color: #ccc;
	cursor: pointer;

	&:hover {
		color: #555;
	}
}

.close {
    font-size: 1.25em;
    font-weight: 200;
    color: #bbb;

    &:hover {
        color: #555;
    }
}
