.root {
    display: flex;
    flex-direction: column;
	flex: 1;
}

.mobile {
	font-size: 1.25em;
}

.actionsBar {
	display: flex;
	margin-bottom: 1em;
    padding-right: 1.5em;
	flex-direction: row;
	justify-content: center;
}

.actionText {
	margin-left: .5em;
}

.createBtn {
	display: flex;
	align-items: center;
    gap: .5em;
	color: #444;
	cursor: pointer;
}

.list {
	display: flex;
	flex-direction: column;
    flex: 1;
	gap: 1.25em;
	overflow: auto;
}

.emptyGroups {
	text-align: center;
	font-size: .9em;
	color: #aaa;
	cursor: default;
}
