.root {
	display: flex;
	align-items: center;
	margin-bottom: .5em;
}

.groupToolbarBtn {
	display: flex;
	margin-right: .75em;
	font-size: .75em;
	color: #333;
	background-color: unset;
}

.menuBtn {
	display: flex;
	width: 1em;
	height: 1em;
	color: #444;
	cursor: pointer;
    font-size: 1em !important;
    user-select: none;
    text-align: center;
}

.menu {
	display: flex;
	flex-direction: column;
	padding: .5em 0;
	border-radius: .125em;
	background-color: white;
	border: 1px solid #d5d5d5;
	border-radius: .25em;
	box-shadow: 0 0 4px 2px rgb(0 0 0 / 3%);
	cursor: pointer;

	&.active {
		display: flex;
	}
}

.actions {
	display: flex;
	margin-left: .5em;
}

.menuItem {
	display: flex;
	align-items: center;
	padding: .5em 1em;
    gap: .75em;
	font-size: .9em;
	color: #444;

	&:hover {
		background-color: #333;
		color: white;
	}
}

.itemIcon {
    width: 15px;
}

.groupName {
	display: flex;
	flex: 1;
	font-weight: bold;
	font-size: 1em;
	color: #444;
	cursor: default;
}

.groupNameInput {
	width: 100%;
	height: 30px;
	font-family: 'Open Sans';
	font-size: 16px;
	font-weight: bold;
	color: #444;
	background-color: transparent;
}

.addRemove {
	font-size: 1em;
}

.active {
	color: #777;
}
