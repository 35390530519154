.root {
	display: flex;
	align-items: center;
	padding: .5em 1em;
	border-bottom: 1px solid #ededed;
}

.col {
	display: flex;
    white-space: nowrap;
}

.storyImg {
	width: 22px;
	margin-right: 4px;
	border-radius: 3px;
}

.feedTitle {
	margin-right: .75em;
	padding: .5em .75em;
	border-radius: 3px;
	font-size: .75em;
	background-color: #f1f1f1;
	color: #444;
	cursor: default;
}

.link {
	flex: 1 1 auto;
    align-items: center;
	overflow: hidden;

	a {
		display: block;
		line-height: normal;
		overflow: hidden;
		text-overflow: ellipsis;
        text-decoration: none;
		white-space: nowrap;
		color: #333;
	}
}

.bookmark {
	margin-left: 10px;
	color: #eee;
	cursor: pointer;

	&:hover {
		color: #444;
	}
}

.time {
	flex: 0 0 auto;
	margin-left: .5em;
	font-size: 13px;
	color: #777;
	cursor: default;
}
