input:focus {
	outline: none;
}

textarea:focus {
	outline: none;
}

input {
	border: none;
	outline: none;
	font-size: 1em;

	&.xs {
		height: 20px;
		padding: 2px 4px;
		font-size: 11px;
	}

	&.sm {
		height: 30px;
		padding: 3px 7px;
		font-size: 12px;
	}

	&.md {
		height: 50px;
		padding: 4px 14px;
		font-size: 14px;
	}

	&.lg {
		height: 50px;
		padding: 5px 10px;
		font-size: 16px;
	}

	
}

input.std, textarea.std {
	width: 100%;
	border: 1px solid #ddd;
}
