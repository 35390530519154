@import '../../../styles/variables.scss';

.root {
	display: flex;
	flex: 1;
	justify-content: space-between;
	/*border-bottom: $blockBorder;*/
	/*box-shadow: 0 3px 7px 0px rgb(0 0 0 / 6%);*/
	/*background-color: #fbfbfb;*/

	&.mobile {
		box-shadow: none;
	}
}

.segment {
	display: flex;
    flex: 1;
}

.left {
	display: flex;
	align-items: center;
}

.center {
	display: flex;
	justify-content: center;
    flex: 4;
}

.right {
	display: flex;
    justify-content: right;
}
