$material-symbols-font-path: '~material-symbols/';
@import 'material-symbols';
@import './variables';
@import './reset';

html, body {
    height: 100%;
    font-family: 'Open Sans';
}

@font-face {
	font-family: 'Lordish';
	src: url('@nl/assets/fonts/Lordish.ttf') format('truetype');
}

@font-face {
	font-family: 'Chunk';
	src: url('@nl/assets/fonts/Chunk.otf') format('opentype');
}

@font-face {
	font-family: 'Chromeye';
	src: url('@nl/assets/fonts/chromeye.ttf') format('truetype');
}

@font-face {
	font-family: 'Font Awesome';
	src: url('@nl/assets/fonts/fa-solid-900.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

#root {
    display: contents;
}

.materialOutlined {
    @extend .material-symbols-outlined;
}

.materialRounded {
    @extend .material-symbols-rounded;
}

.materialSharp {
    @extend .material-symbols-sharp;
}
