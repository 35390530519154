.root {
	display: flex;
	align-items: center;
	padding: 0 1em;
}

.logo {
	width: 30px;
	margin-right: .5em;
	opacity: .9;
}

.title {
	font-family: 'Chromeye', 'Judson', 'Open Sans';
	font-size: 1.75em;
	color: #444;
	cursor: default;
}
