.root {
	display: flex;
	flex-direction: column;
	gap: 1em;
	overflow-x: hidden;
}

.mobile {
	font-size: 1.25em;
}

.emptyBookmarks {
	text-align: center;
	font-size: .9em;
	color: #aaa;
	cursor: default;
}
