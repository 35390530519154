@import '@ui/styles/variables.scss';

.root {
    display: flex;
    flex: 1;
    align-items: center;
    max-width: $lgCenterWidth;
    justify-content: space-between;
}

.left {
    display: flex;
    flex-grow: 1;
}

.center {
    display: flex;
    width: 66.66%;
}

.right {
    display: flex;
    flex-grow: 1;
    justify-content: right;
}

.cont {
    display: contents;
}

.inputCont {
	display: flex;
	flex: 1;
}

.feedView {
	display: flex;
	flex: 1;
	height: 43px;
    padding-left: 0px;
	border: 1px solid white;
	border-radius: 5px;
	/*box-shadow: 0 0 4px 2px rgb(0 0 0 / 3%);*/
	color: #777;
	/*background-color: #f5f5f5;*/
	overflow-x: hidden;
	overflow-y: hidden;
    scrollbar-width: thin;
    transition: border 100ms ease-in;

    &:hover {
        overflow-x: auto;
    }

    &:not(.empty):hover {
        border: 1px solid #999;
    }

    &.menuOpen {
        border: 1px solid #999;
        overflow-x: auto;
    }

    &.empty {
        background-color: #f9f9f9;
    }

    &.empty:hover {
        background-color: #f3f3f3;
    }
}

.feedViewMenuCont {
    display: flex;
}

.feedViewMenu {
	display: flex;
	max-height: 30em;
	flex: 1;
	background-color: white;
    border: 1px solid #999;
	border-radius: 5px;
	box-shadow: 0 0 4px 2px rgb(0 0 0 / 3%);
	overflow: auto;
}
