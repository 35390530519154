.body {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.sectionLabel {
	display: flex;
	margin-bottom: .25em;
	font-size: 1.2em;
}

.list {
	display: flex;
	flex-direction: column;
}

.groupList {
	margin-bottom: 1em;
}

.pubsAndFeedsList {
	
}

.groupRow {
	display: flex;
	margin: 0;
	padding: .25em .5em;
	color: #333;

	&.active {
		background-color: #111;
		color: white;
	}
}

.folderIconCont {
	display: flex;
}

.groupName {
	display: flex;
    justify-content: center;
	margin-bottom: .75em;
}

.pubsAndFeedsCont {
	display: flex;
    flex: 1;
	justify-content: center;
	max-height: 200px;
	overflow: auto;
}

.pubsAndFeeds {
	display: flex;
	flex-direction: column;
    flex: 1;
	gap: .5em;
}

.pubAndFeeds {
	display: flex;
	flex-direction: column;
}

.pubRow {
	display: flex;
	align-items: center;
	margin: 0;
	padding: .25em .5em;
	cursor: pointer;

	&.active {
		background-color: #111;
		color: white;
	}
}

.pubIconCont {
	display:flex;

	img {
		width: 1.25em;
		height: 1.25em;
		border-radius: 3px;
	}
}

.pubName {
	display: flex;
	margin-left: .5em;
}

.feedsList {
	display: flex;
	margin: 0;
}

.feedRow {
	display: flex;
	padding: .25em 2.25em;
	cursor: pointer;

	&.active {
		background-color: #111;
		color: white;
	}
}

.feedTitle {
	display: flex;
	font-size: .9em;
}

.check {
	display: flex;
	margin-right: .75em;
	align-items: center;
	font-size: .75em;
	color: white;

	&.active {
		color: #333;
	}
}
