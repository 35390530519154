@import '../../ui/styles/variables.scss';

.root {
	display: flex;
	align-items: center;
}

.searchInput {
	composes: rd3 from '../../ui/styles/common.scss';
	height: 2.5em;
	padding: 0 1em;
	flex: 1;
	border: 1px solid #d5d5d5;
	box-shadow: 0 0 4px 2px rgb(0 0 0 / 3%);
}

.close {
	display: flex;
	position: relative;
	width: 1em;
	right: .5em;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	color: #444;

	&.mobile {
		right: 1.5em;
	}
}
