.root {
    display: flex;
    align-items: center;
	justify-content: center;
    gap: .5em;
    padding-right: .5em;
	font-size: 1em;
	cursor: pointer;
}

.menu {
	display: flex;
	flex-direction: column;
	padding: .5em 0;
	border-radius: .125em;
	background-color: white;
	border: 1px solid #d5d5d5;
	border-radius: .25em;
	box-shadow: 0 0 4px 2px rgb(0 0 0 / 3%);
	cursor: pointer;
}

.menuBtn {
	display: flex;
    align-items: center;
    justify-content: center;
    width: 3em;
    height: 3em;
	color: #444;
	cursor: pointer;
    font-size: 1em !important;
    user-select: none;
    text-align: center;
}

.menuItem {
	display: flex;
	align-items: center;
    gap: .5em;
	padding: .5em 1em;
	font-size: .9em;
	color: #444;

    &.active {
		background-color: #333;
        color: white;
    }
}

.itemIcon {
    display: flex;
}

.itemText {
    display: flex;
}
