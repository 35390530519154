$menuWidth: 350px;
$negMenuWidth: -350px;

.root {
	display: flex;
	flex-direction: column;
	position: fixed;
	left: 0;
	height: 100%;
	width: $menuWidth;
	-webkit-transition: all .15s cubic-bezier(.42, 0, 1, 1);
	transition: all .15s cubic-bezier(.42, 0, 1, 1);
	border-right: 1px solid #ccc;
	box-shadow: 0 1px 5px rgb(0 0 0 / 10%);
	z-index: 200;
	background-color: white;
	overflow: hidden;

	&.menuOpen {
		transform: translate3d(0px, 0, 0);
	}

	&.menuClosed {
		transform: translate3d($negMenuWidth, 0, 0);
	}
}

.header {
	display: flex;
	justify-content: space-between;
}

.title {
	font-family: 'Chromeye', 'Judson', 'Open Sans';
	padding: .5em .75em;
	font-size: 1.75em;
	color: #555;
	cursor: default;
}

.close-cont {
	display: flex;
    align-items: center;
    justify-content: center;
	cursor: pointer;
    transform: rotate(45deg);
    color: #ddd;

    &:hover {
        color: #555;
    }

    &.pinned {
        color: #555;
        transform: none;
    }
}

.close {
    font-weight: 400;
    color: #555;
}

.link {
    display: flex;
    flex: 1;
    height: 30px;
    align-items: center;
    text-decoration: none;
    color: inherit;
}

.menuCont {
	display: flex;
    flex: 1;
	width: $menuWidth * 2;
	-webkit-transition: all .15s cubic-bezier(.42, 0, 1, 1);
	transition: all .15s cubic-bezier(.42, 0, 1, 1);
    overflow: auto;

	&.menuContentOpen {
		transform: translate3d($negMenuWidth, 0, 0);
	}

	&.menuContentClosed {
		transform: translate3d(0px, 0, 0);
	}
}

.menuCol {
	display: flex;
	flex-direction: column;
}

.navMenu {
	display: flex;
	flex-direction: column;
	padding: .75em 0;
	gap: .75em;
}

.sidebarNavBtn {
	display: flex;
	align-items: center;
	height: 30px;
    padding: 0 32px;
	color: #444;
	cursor: pointer;

    &.active {
        border-left: 3px solid #333;
        padding: 0 30px;
    }
}

.listMenuCont {
	display: flex;
	flex-direction: column;
	width: $menuWidth;
    margin-bottom: 1em;
	padding: 12px 32px;
	gap: .75em;
}

.menuContent {
	display: flex;
	flex-direction: column;
	width: $menuWidth;
}

.outer {
    display: flex;
    width: 3em;
}

.menuBack {
    display: flex;
	margin-bottom: .5em;
	padding: 0.75em 2em;
	color: #444;
	cursor: pointer;
}

.tabTitle {
	margin-bottom: 1em;
	font-size: 1.25em;
	text-align: center;
	cursor: default;
}

.submenuCont {
    display: flex;
    flex: 1;
	width: $menuWidth;
	padding-left: 2em;
    overflow: auto;
}


.backBtn {
    width: 1em;
}

.backText {
	margin-left: 1em;
}

.sidebarBtn {
	display: flex;
	align-items: center;
	height: 30px;
	color: #444;
	cursor: pointer;
}

.btnText {
	flex: 1;
	margin-left: 1em;
    user-select: none;
}

.lowerMenu {
	display: flex;
	padding: 1em 2em;
	color: #444;
	cursor: pointer;
}
