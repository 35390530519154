.root {
    display: flex;
    align-items: center;
	justify-content: center;
    gap: .5em;
    padding-right: .5em;
	font-size: 1em;
	cursor: pointer;
}

.btn {
	cursor: pointer;
	color: #eee;

    &.active {
        color: #444;
    }
}
