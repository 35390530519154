@import '@ui/styles/variables.scss';

.root {
	display: flex;
}

.body {
    display: flex;
    flex: 1;
    justify-content: center;
}

.formRow {
    display: flex;
    flex-direction: column;
}

.label {
    font-size: .75em;
    margin-bottom: .75em;
}

.groupNameInput {
	height: 2.5em;
	padding: .25em 1em;
	border: $inputBorder;
	border-radius: 3px;
	box-shadow: $inputBoxShadow;
}
