
.groupItem {
	display: flex;
	align-items: center;
}

.imgCont {
	display: flex;
}

.groupItemImg {
	display: flex;
	width: 1.25em;
	height: 1.25em;
	border-radius: .125em;
}

.groupLabel {
	display: flex;
	margin-left: .5em;
	color: #333;
	cursor: default;
}
