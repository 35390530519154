@import '@ui/styles/variables.scss';

.btnSubscribe {
	composes: sm from '@ui/styles/buttons.scss';
	composes: rd2 from '@ui/styles/common.scss';
}

.storyRow {
	display: flex;
}

.logoCont {
	img {
		border-radius: 3px;
	}
}

.title {
	margin-left: 1em;
    font-size: .9em;
    line-height: 1.25em;
}
