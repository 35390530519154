$itemHeight: 22px;

.root {
	display: flex;
    height: $itemHeight;
    padding-right: 1em;
}

.header {
	display: flex;
	align-items: center;
}

.logo {
	width: 22px;
    height: 22px;
	border-radius: 3px;
}

.logoCont {
	display: flex;
    height: $itemHeight;
}

.title {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	justify-content: center;
	margin-left: .5em;
	font-size: .9em;
	overflow: hidden;
	color: #444;
	cursor: default;
}

.link {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
    text-decoration: none;
	cursor: pointer;
	color: #333;
    height: $itemHeight;
    line-height: $itemHeight;
}

.unsub {
    display: flex;
	cursor: pointer;
}

.close {
    font-weight: 200;
    color: #ccc;

    &:hover {
        color: #555;
    }
}
