@import '@ui/styles/variables.scss';

.root {
	display: flex;
	flex-direction: column;
	height: 100%;
    gap: 2.5em;
}

.mainModal {
    position: relative;
	width: 400px;
	padding: 2em;
	outline: none;
	background-color: white;
	border-radius: 4px;
}

.mainModalOverlay {
	display: flex;
	position: fixed;
	background-color: rgba(37, 37, 37, 0.75);
	inset: 0px;
	justify-content: center;
	align-items: center;
	z-index: 200;
}

.header {
	font-size: 1.25rem;
	color: #222;
}

.mainContent {
    display: flex;
	flex: 1;
}

.footer {
	display: flex;
    justify-content: flex-end;
    gap: .75em;
}

.btn {
	padding: .5em 2em;
	border-radius: 4px;
	font-size: 1em;
	font-family: 'Open Sans';
	cursor: pointer;
}

.btnConfirm {
	background-color: black;
	color: white;
}

.btnCancel {
	background-color: white;
	color: black;
	border: 1px solid #000;
}

.close {
    position: absolute;
    top: .75em;
    right: .75em;
    font-size: 1.4em;
    font-weight: 200;
    color: #555;
    cursor: pointer;
}
