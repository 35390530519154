$hoverColor: #e7e7e7;

.root {
	display: flex;
	flex-direction: column;
    flex: 1;

	&.mobile {
		font-size: 1.25em;
	}
}

.sectionLabel {
	display: flex;
	margin-bottom: .25em;
    padding: 1em 1em 0;
	font-size: 1.2em;
	cursor: default;
}

.list {
	display: flex;
	flex-direction: column;
}

.groupList {
}

.pubsAndFeedsList {
	
}

.groupRow {
	display: flex;
	margin: 0;
	padding: .25em 1.5em;
	color: #333;
	cursor: pointer;

    &:hover {
        background-color: $hoverColor;
    }

	&.active {
		background-color: #111;
		color: white;
	}
}

.emptyGroups {
	display: flex;
	margin: 0;
	padding: .75em;
	font-size: .8em;
	color: #777;
	cursor: default;
}

.folderIconCont {
	display: flex;
}

.groupName {
	display: flex;
	margin-left: .5em;
}

.pubsAndFeeds {
	display: flex;
	flex-direction: column;
}

.pubAndFeeds {
	display: flex;
	flex-direction: column;
}

.emptySubs {
	display: flex;
	margin: 0;
	padding: .75em;
	font-size: .8em;
	color: #777;
	cursor: default;
}

.pubRow {
	display: flex;
	align-items: center;
	margin: 0;
	padding: .25em 1.5em;
	cursor: pointer;

    &:hover {
        background-color: $hoverColor;
    }

	&.active {
		background-color: #111;
		color: white;
	}
}

.pubIconCont {
	display:flex;

	img {
		width: 1em;
		height: 1em;
		border-radius: .125em;
	}
}

.pubName {
	display: flex;
	margin-left: .5em;
}

.feedsList {
	display: flex;
	margin: 0;
}

.feedRow {
	display: flex;
	padding: .25em 3em;
	cursor: pointer;

    &:hover {
        background-color: $hoverColor;
    }

	&.active {
		background-color: #111;
		color: white;
	}
}

.feedTitle {
	display: flex;
	font-size: 14px;
}

.check {
	display: flex;
	margin-right: .75em;
	align-items: center;
	font-size: .75em;
	color: transparent;

	&.active {
		color: #333;
	}
}

.clear {
    position: absolute;
    right: 0;
    font-size: 13px;
    margin: 5px 20px 0 0;
    padding: 5px 7px;
    border: 1px solid #ddd;
    border-radius: 3px;
    background-color: #eee;
    color: #444;
    cursor: pointer;
}
