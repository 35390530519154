.root {
	display: flex;
	flex-direction: column;
    padding-right: 1.5em;
}

.groupItemsList {
	display: flex;
	flex-direction: column;
	gap: .5em;
}

.empty {
    color: #ccc;
    font-size: .75em;
    text-align: center;
    cursor: default;
}
