.root {
	display: flex;
	flex: 1;
	align-items: center;
	font-size: 1rem;
	margin-right: .5em;
}

.btns {
	display: flex;
	flex-direction: row-reverse;
	position: relative;
	left: 3.5em;
	color: #444;
	cursor: pointer;
}

.btn {
	display: flex;
	width: 1.75em;
	align-items: center;
	justify-content: center;
	font-size: .7em;

	&.active {
		font-size: 1em;
	}
}

.btnList {
	span {
	}
}

.inputCont {
	display: flex;
	flex: 1;
}

.inputRoot {
	display: flex;
	flex: 1;
	border: 1px solid #d5d5d5;
	border-radius: 0.25em;
	box-shadow: 0 0 4px 2px rgb(0 0 0 / 3%);
	background-color: white;
}

.input {
	flex: 1;
	height: 2.5em;
	padding-left: 4.25em;
	border-radius: .25em;
	color: #555;
}

.feedView {
	display: flex;
	flex: 1;
	height: 2.5em;
	max-width: 45em;
	padding-left: 4.5em;
	border: 1px solid #d5d5d5;
	border-radius: .25em;
	box-shadow: 0 0 4px 2px rgb(0 0 0 / 3%);
	color: #555;
	background-color: white;
	overflow: hidden;
}

.feedViewMenu {
	display: flex;
	max-height: 30em;
	padding: 1em;
	flex: 1;
	background-color: white;
	border: 1px solid #d5d5d5;
	border-radius: .25em;
	box-shadow: 0 0 4px 2px rgb(0 0 0 / 3%);
	overflow: auto;
}

.forRef {
	display: flex;
	flex: 1;
}
