.root {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 1.25em 2em;
	background-color: white;
}

.header {
	display: flex;
	margin-bottom: 1em;
	justify-content: space-between;
	align-items: center;
}

.main {
	display: flex;
	flex-direction: column;
	flex: 1;
	gap: 2em;
	overflow: auto;
}

.title {
	font-size: 1.5em;
}

.invisible {
	visibility: hidden;
}

.back {
	display: flex;
	font-size: 1.25em;
	cursor: pointer;
	color: #444;
}

.close {
	display: flex;
	cursor: pointer;

	img {
		width: 1em;
		height: 1em;
	}
}
.icon {
	color: #444;
}

.menuRow {
	display: flex;
	font-size: 1.5em;
}

.menuTitle {
	margin-left: .75em;
}

.link {
	color: black;
}
