@import '@ui/styles/variables.scss';

.root {
	display: flex;
	flex: 1;
    overflow: auto;

	&.mobile {
		border: block;
	}
}

.cont {
	display: flex;
	flex-direction: column;
    /*width: $lgCenterWidth;*/
    flex: 1;
    overflow: auto;

	&.mobile {
		display: block;
		width: initial;
		max-width: 100%;
	}
}

.more {
    display: flex;
    justify-content: center;
    padding: 1em;
    background-color: #e9e9e9;
    font-size: .9em;
    color: #333;
    cursor: pointer;

    &:hover {
        background-color: #eee;
    }
}

.emptyList {
	position: absolute;
	left: 0;
	right: 0;
	width: fit-content;
	margin: 1em auto 0 auto;
	text-align: center;
	font-weight: bold;
	color: #aaa;
	cursor: default;
}
