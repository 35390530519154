.root {
	display: flex;
	flex: 1;
	align-items: center;
    padding: 0 8px;
	font-size: .8rem;
	gap: 4px;
	cursor: pointer;
}

[title="Feed Selection"]:empty:not(:focus):hover:before {
    content: attr(title);
    padding-left: 10px;
    color: #999;
}

.cont { 
	display: flex;
	flex: 1;
}

.tooltip {
	padding: 5px;
	pointer-events: none;
}

.item {
	display: flex;
	align-items: center;
    height: 27px;
    padding: 0 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #e5e5e5;
    color: #444;
}

.icon {
    display: flex;
}

.label {
    display: flex;
	margin-left: .25em;
	white-space: nowrap;
}

.img {
	width: 18px;
	height: 18px;
	border-radius: 3px;
}

.folder {
	font-size: 16px;
}
