.root {
	display: flex;
	max-height: 13em;
	padding: 1.5em 2em;
	border-bottom: 1px solid #ededed;
	color: #333;

	&.mobile {
		padding: .75em 1.25em;
		max-height: 16em;
	}
}

.col {
	display: flex;
	flex-direction: column;
}

.content {
	display: flex;
	flex: 1;
	padding-left: 1.5em;
	overflow: hidden;
};

.storyTitle {
	font-size: 1.85em;
	margin-bottom: .25em;
	line-height: normal;
	overflow-x: clip;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-weight: 600;
	color: #444;

	&.mobile {
		font-size: 1.25em;
		white-space: initial;
	}

	a {
		color: inherit;
        text-decoration: none;
	}
}

.subHeader {
	display: flex;
	align-items: center;
	margin-bottom: .25em;
	font-size: .9em;
	cursor: default;

	&.mobile {
		font-size: .8em;
	}
}

.logo {
	display: flex;
}

.pubLogo {
	width: 1.25em;
	margin-right: .5em;
	border-radius: 3px;
}

.date {
	margin-left: 1em;
	color: #777;
}

.bookmark {
	margin-left: 10px;
	color: #eee;
	cursor: pointer;

	&:hover {
		color: #444;
	}
}

.description {
	max-height: 4.5em;
	line-height: 1.5;
	overflow: hidden;
}

.imgCont {
	display: flex;
	width: 152px;
	height: 152px;
	max-height: 9.5em;
	border-radius: .25em;
	overflow: hidden;
    
    &.missing {
        background-color: #f4f4f4;
    }
}

.img {
	width: auto;
	height: 152px;
	object-fit: cover;
}
