.root {
	margin-right: .75em;
	white-space: nowrap;
	cursor: pointer;
}

.title {
	font-size: .75em
}

.icon {
	margin-left: .5em;
	font-size: .6em;
}
