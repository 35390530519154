@import '@ui/styles/variables.scss';

.center {
    display: flex;
    flex: 1;
    align-items: center;
    max-width: $lgCenterWidth;
    justify-content: space-between;
}

.centerLeft {
    display: flex;
    flex-grow: 1;
}

.centerCenter {
    display: flex;
    flex-grow: 2;
}

.centerRight {
    display: flex;
    flex-grow: 1;
    justify-content: right;
}
