.body {
    display: flex;
    flex: 1;
    justify-content: center;
    color: #333;
}

.groupName {
    margin-left: .5em;
}

.btn {
	padding: 10px 16px;
	border-radius: 3px;
	font-size: 12px;
	font-family: 'Open Sans';
	cursor: pointer;
}

.btnDelete {
	margin-right: 10px;
	background-color: black;
	color: white;
}

.btnCancel {
	background-color: white;
	color: black;
	border: 1px solid #000;
}
