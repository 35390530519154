@import '@ui/styles/variables.scss';

.header {
    display: flex;
    justify-content: space-between;
}

.pubTitle {
    display: flex;
}

.pubBody {
	display: flex;
	justify-content: center;
}

.closeRow {
	display: flex;
	position: relative;
	top: -10px;
	right: -10px;
	justify-content: flex-end;
	cursor: pointer;
    color: #333;
}

.pubRow {
	display: flex;
    align-items: center;
    gap: .75em;
}

.logoCont {
	display: flex;

	img {
		width: 40px;
		border-radius: 3px;
	}
}

.close {
    font-size: 1.25em;
    font-weight: 200;
    color: #555;
    cursor: pointer;
}

.row {
	display: flex;
}

.inputCont {
	display: flex;
    align-items: center;
}

.text {
	display: flex;
    align-items: center;
    margin-left: .25em;
}

.subAll {
	margin-top: 1em;
	justify-content: center;
	font-size: .9em;
	cursor: pointer;
	color: #333;
}

.orOther {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: .9em;
}

.or {
	margin-top: 1.25em;
	margin-bottom: 1.25em;
	cursor: default;
}

.otherOption {
    display: flex;
    align-items: center;
	cursor: pointer;
}

.back {
    display: flex;
    align-items: center;
    margin-top: 1em;
    cursor: pointer;
}

.backIcon {
    font-weight: 200;
}

.feeds {
	display: flex;
	margin-bottom: 2em;
	flex-direction: column;
	gap: .25em;
	font-size: .9em;
	cursor: default;
}

.btnSubscribe {
	padding: 0.75em 2.25em;
	border-radius: 5px;
	font-size: 1.1em;
	font-family: 'Open Sans';
	cursor: pointer;
	background-color: black;
	color: white;
}
